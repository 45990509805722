<template>
  <div class="wrap">
    <div class="title_boxs">
      <img class="titleTip" src="@/assets/img/partner/titleTip.png" alt="" @click="reBackCourse" />
      <div class="kcName">{{ className }}</div>
      <div class="item-validPeriod">
        {{ "课程有效期：" + calculateTheValidityPeriod(validityTime) }}
      </div>
    </div>
    <div class="year_box" v-if="type == 1 && productLine == 3">
      <!-- 专属教辅 -->
      <img src="@/assets/img/partner/assistant.png" @click="openTextBook" />
      <!-- 专属资料 -->
      <img src="@/assets/img/partner/information.png" @click="openMaterial" />
      <!-- 专属题库 -->
      <img src="@/assets/img/partner/questionbank.png" @click="openQuestionBank" />
      <!-- 专属考场 -->
      <img src="@/assets/img/partner/examinationroom.png" @click="openRoom" />
      <!-- 专属教服 -->
      <img src="@/assets/img/partner/tutorial.png" @click="openEducate" />
    </div>
    <div class="content_box">
      <div v-for="item in courseNameList" :key="item.kcId" class="content_item">
        <div class="name_box">
          <span class="name twoEllipsis">{{ item.kcName }}</span>
          <div class="dis_flex_start" style="margin-top: 20px">
            <div v-for="items in item.thisTypes" :key="items" class="tag">
              <el-image class="tab_img" :src="require('@/assets/img/partner/bofang.png')"
                v-if="courseType[parseInt(items) - 1] === '直播课'"></el-image>
              <span>{{ courseType[parseInt(items) - 1] }}</span>
            </div>
          </div>
        </div>
        <!-- <div class="progress_bar">
          <div class="schedule" :style="`width: ${item.studyRate}%;`" />
        </div> -->
        <div class="btn_box">
          <!-- <div class="schedule_box">
            <img
              class="img"
              src="@/assets/img/homeSeventh/sandClock.png"
              alt=""
            />
            <span class="text"
              >已学：<span>{{ item.studyRate }}%</span>
            </span>
          </div> -->
          <div class="btn" @click="toCourse(item)">观看课程</div>
        </div>
      </div>
    </div>
    <!-- <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="664px" center :before-close="handleClose">
      <template v-if="dialogTitle === '专属教辅'">
        <dedicatedAssistant :classId="id" />
      </template>
<template v-else-if="dialogTitle === '专属资料'">
        <datum :classId="id" />
      </template>
<template v-else-if="dialogTitle === '专属题库'">
        <classesQuestion :classes-id="id" />
      </template>
<template v-else-if="dialogTitle === '专属考场'">
        <room-dialog :classes-id="id" />
      </template>
<template v-else-if="dialogTitle === '专属教服'">
        <uniform />
      </template>
</el-dialog>-->
    <!-- 专属教服弹窗 -->
    <educate-dialog ref="educateDialog" :direction="direction" :educate-visible="educateVisible"
      @educateClose="educateClose" />
    <!-- 专属教材弹窗 -->
    <text-book-dialog ref="textBookDialog" :direction="direction" :book-visible="bookVisible" :is-purchase="true"
      @bookClose="bookClose" />
    <!-- 专属考场弹窗 -->
    <el-dialog title="专属考场" class="new_bac" :visible.sync="roomVisible">
      <room-dialog v-if="roomVisible" ref="roomDialog" :direction="direction" :classes-id="id" :is-purchase="true" />
    </el-dialog>

    <!-- 专属资料 -->
    <material ref="material" :material-visible="materialVisible" :classes-id="id" :direction="direction"
      @materialClose="materialClose" />
    <!-- 专属题库 -->
    <!--   <question-bank
      ref="questionBank"
      :questionBankVisible="questionBankVisible"
      @questionBankClose="questionBankClose"
    ></question-bank> -->
    <el-dialog title="专属题库" class="new_bac" :visible.sync="questionBankVisible">
      <classes-question :classes-id="id" :is-purchase="true" />
      <!-- <exam-view
        :direction="direction"
        v-if="questionBankVisible"
        :IsPurchase="IsPurchase"
        :productLine="3"
        :classesId="id"
      /> -->
    </el-dialog>
  </div>
</template>

<script>
import { getInfo } from "@/api/cookies";
import { selectRateByClassIdYearStudentIdPC } from "@/api/mykecheng";
import { Know } from "@/api/know";
import dedicatedAssistant from "./components/dedicatedAssistant.vue";
import datum from "./components/datum.vue";
import classesQuestion from "@/components/Know/classCom/classes-question.vue";
import {
  getClass,
} from '@/api/home.js'
import uniform from "./components/uniform.vue";
import material from '@/views/class/dialog/materialDialog.vue'
import roomDialog from '@/views/class/dialog/roomDialog.vue'
import educateDialog from '@/views/class/dialog/educateDialog.vue'
import textBookDialog from '@/views/class/dialog/textBookDialog.vue'

const know = new Know();
export default {
  data() {
    return {
      userInfo: null,
      id: null, // 套餐ID
      yearKc: [],
      className: "",
      myCourseShow: true, // 我的课程标签
      currentTag: true, // 当前页标签
      validityTime: null, // 当前页标签
      year: "",
      courseNameList: [],
      courseType: ["直播课", "录播课", "直播课", "直播课", "面授课"],
      dialogVisible: false, // 专属资料显隐
      dialogTitle: "",
      type: "",
      productLine: "",
      educateVisible: false, // 专属教服显隐
      bookVisible: false, // 专属教材显隐
      roomVisible: false, // 专属考场显隐
      materialVisible: false, // 专属资料显隐
      questionBankVisible: false, // 专属题库显隐
      direction: '',
      detalilsList: {},

    };
  },
  components: {
    dedicatedAssistant,
    datum,
    classesQuestion,
    roomDialog,
    uniform,
    textBookDialog,
    roomDialog,
    material,
    educateDialog,


  },
  created() {
    this.id = this.$route.query.id;
    this.className = this.$route.query.className;
    this.kcbClassId = this.$route.query.kcbClassId;
    this.validityTime = this.$route.query.validityTime;
    this.type = this.$route.query.type;
    this.productLine = this.$route.query.productLine
    this.addTime = this.$route.query.addTime
    // console.log(this.type);
    this.userInfo = getInfo();
    const loading = this.$loading({
      lock: true,
      text: '加载中',
      spinner: '',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    getClass(this.id).then((res) => {
      if (res.code == 200) {
        this.detalilsList = JSON.parse(JSON.stringify(res.data.BX))
      }
      loading.close();
    })
    this.getList();
  },
  methods: {
    // getYear () {
    //   know
    //     .classesTableKsTime(
    //       this.type == 16 ? this.kcbClassId : this.id,
    //       this.userInfo && this.userInfo.id ? this.userInfo.id : undefined
    //     )
    //     .then((res) => {
    //       console.log(res, '-----------year')
    //       if (res.code == 0) {
    //         this.yearKc = res.data.yearKc
    //         this.year = res.data.yearKc[0].year
    //         this.getList()
    //         // this.currentYear = res.data.yearKc[0].year;
    //         // this.courseName = res.data.yearKc[0].yearKcNames;
    //       }
    //     })
    // },
    getList() {
      const data = {
        classesId: this.id,
        packageId: this.type == 16 ? this.id : null,
        // year: this.year,
        studentId: this.userInfo.id,
        type: this.type,
      };
      selectRateByClassIdYearStudentIdPC(data).then((res) => {
        this.courseNameList = res.msg.map((item) => {
          return {
            ...item,
            thisTypes: item.courseTypes.split(","),
          };
        });
        console.log(this.courseNameList);
      });

    },
    reBackCourse() {
      this.$router.go(-1);
    },
    calculateTheValidityPeriod(time) {
      const currentTime = new Date();
      time = time ? time.toString().replace(/-/g, "/") : time;
      const targetTime = new Date(time);
      const difference = targetTime.getTime() - currentTime.getTime();
      if (difference < 0) {
        return "已过期";
      }
      const year = Math.floor(difference / 1000 / 60 / 60 / 24 / 365);
      const days =
        Math.floor(difference / 1000 / 60 / 60 / 24) - Math.floor(year * 365);
      // if ( year > 0 && days > 0 ) {
      //     return year + '年' + days + '天'
      // } else if ( year <= 0 && days > 0 ) {
      //     return days + '天'
      // }
      return year + "年" + days + "天";
    },
    toCourse(item) {
      console.log(item, this.$router.currentRoute.path);
      this.$router.push({
        path: `/partnerPage/courseLearningRate?kcbClassId=${this.kcbClassId}&addTime=${this.addTime}&type=${this.type}&id=${this.id}&kcId=${item.kcId}&className=${this.className}&kcName=${item.kcName}&itype=${item.type}`,
        query: {
          courseTypes: item.courseTypes,
          validityTime: this.validityTime,
          details: JSON.stringify(this.courseNameList),
          items: JSON.stringify(item),

        },
      });
    },
    changeYear(item) {
      this.year = item.year;
      this.getList();
    },
    // 关闭
    handleClose() {
      this.dialogVisible = false;
    },
    /* 打开教服弹窗 */
    openEducate() {
      this.educateVisible = true
      this.$refs.educateDialog.init(
        this.detalilsList.jfServer,
        this.detalilsList
      )
    },
    /* 关闭教服弹窗 */
    educateClose() {
      this.educateVisible = false
    },
    /* 打开教材弹窗 */
    openTextBook() {
      this.bookVisible = true
      this.$refs.textBookDialog.init(
        this.id,
        true,
        this.detalilsList.tiitle
      )
    },
    /* 关闭教材弹窗 */
    bookClose() {
      this.bookVisible = false
    },
    /* 打开考场弹窗 */
    openRoom() {
      this.roomVisible = true
      // this.$refs.roomDialog.init(this.id, this.IsPurchase);
    },
    /* 关闭考场弹窗 */
    roomClose() {
      this.roomVisible = false
    },
    /* 打开专属资料 */
    openMaterial() {
      this.materialVisible = true
      this.$refs['material'].init(this.id, true)
    },
    /* 关闭专属资料 */
    materialClose() {
      this.materialVisible = false
    },
    /* 打开专属题库 */
    openQuestionBank() {
      this.questionBankVisible = true
      // this.$refs.questionBank.init(this.id, this.IsPurchase);
    },
    /* 关闭专属题库 */
    questionBankClose() {
      this.questionBankVisible = false
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  width: 1050px;
  background: #ffffff;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  padding-top: 24px;

  .title_boxs {
    position: relative;
    width: 1050px;
    height: 50px;
    background: #f5f6f7;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    display: flex;
    padding: 0 20px;
    align-items: center;
    justify-content: flex-start;

    .titleTip {
      width: 26px;
      margin-right: 16px;
    }

    .kcName {
      height: 24px;
      font-size: 16px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      line-height: 24px;
    }

    .item-validPeriod {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      top: 0px;
      right: 0px;
      width: 166px;
      height: 28px;
      background: #e2eeff;
      opacity: 1;
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #458bd8;
    }
  }

  .year_box {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    img {
      width: 190px;
      height: 68px;
    }
  }

  .content_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;

    .content_item {
      position: relative;
      padding: 16px 20px;
      width: 510px;
      // height: 117px;
      // background: #e6f4ff;
      background: url("~@/assets/img/partner/liebg.png") no-repeat;
      background-size: 100% 100%;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      // box-shadow: 0px 0px 12px 1px rgba(204, 204, 204, 0.34);
      // border: 1px solid rgba(181, 201, 255, 0.3);
      margin-bottom: 20px;

      .name_box {
        // display: flex;
        margin-bottom: 20px;

        .name {
          max-width: 320px;
          margin-right: 8px;
          overflow: hidden;
          font-size: 22px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
          color: #333333;
        }

        .tag {
          height: 22px;
          background: rgba(254, 110, 14, 0.1);
          border-radius: 3px 3px 3px 3px;
          opacity: 1;
          font-size: 11px;
          padding: 0 7px;
          color: #fe6e0e;
          text-align: center;
          line-height: 21px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;

          .tab_img {
            width: 14px;
            height: 10px;
            margin-right: 2px;
          }

          span {
            // height: 12px;
            font-size: 12px;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 400;
            color: #fe6e0e;
          }
        }

        .tag:nth-last-child(1) {
          margin-right: 0;
        }
      }

      .progress_bar {
        width: 350px;
        height: 10px;
        background-color: #efefef;
        border-radius: 5px;
        margin-bottom: 14px;

        .schedule {
          background: linear-gradient(90deg, #a5bdff 0%, #2871ff 100%);
          height: 100%;
          width: 0;
          border-radius: 5px;
        }
      }

      .btn_box {
        position: absolute;
        bottom: 20px;
        right: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .schedule_box {
          display: flex;
          align-items: center;

          .img {
            width: 12px;
            height: 14px;
            margin-right: 5px;
          }

          .text {
            color: #777777;
            font-size: 14px;

            span {
              font-weight: 600;
            }
          }
        }

        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 140px;
          height: 36px;
          background: #006de7;
          border-radius: 18px 18px 18px 18px;
          opacity: 1;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
}

::v-deep .el-tag--plain {
  color: #4a6af0;
  border-color: #4a6af0;
}

::v-deep .el-tag {
  height: 28px;
  line-height: 28px;
}

::v-deep .el-tag--info {
  border-color: #e9e9eb;
  color: #909399;
}

.iconfont {
  font-size: 14px;
}

::v-deep .new_bac {
  .el-dialog {
    background: url(~@/assets/img/study/1.png);
    background-size: 100% 100%;
    border-radius: 20px !important;
    width: 690px;
    height: 494px;

    .el-dialog__title {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: bold;
      font-size: 22px;
      color: #1D315B;
    }

    .el-dialog__close {
      font-size: 23px;
    }

    .el-dialog__body {
      padding: 0px !important;
    }
  }
}
</style>
<style>
.is-active {
  width: auto !important;
  height: auto !important;
}
</style>
